/*CreateAdmin*/
.form-group {
  margin-bottom: 15px;
}

.span-error {
  font-size: 13px;
  color: red;
  font-weight: bold;
  display: inline-block;
  margin-bottom: 12px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #333;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

/*Admin*/
.super-admin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*height: 100vh;*/
  background-color: white;
}

.super-admin-header {
  width: 100%;
  padding: 20px;
  background: linear-gradient(to bottom right, #ff7e5f, #feb47b);
  /* border-bottom-left-radius: 50px; */
  text-align: center;
  color: white;
}

.super-admin-card {
  background-color: #f9f9f9;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0;
  width: 80%;
  max-width: 400px;
}

.super-admin-card p {
  margin: 10px 0;
  font-size: 16px;
  color: #333;
}

.super-admin-button {
  width: 80%;
  max-width: 400px;
  padding: 10px;
  margin: 20px 0;
  background: linear-gradient(to bottom right, #ff7e5f, #feb47b);
  border: none;
  color: white;
  border-radius: 25px;
  cursor: pointer;
}

.super-admin-button:hover {
  background: linear-gradient(to bottom right, #feb47b, #ff7e5f);
}

/* end Admin*/

.max-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*height: 100vh;*/
  background-color: white;
}

.max-header {
  width: 100%;
  height: 200px;
  background: linear-gradient(to bottom right, #ff7e5f, #feb47b);
  border-bottom-left-radius: 50px;
}

.max-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -100px;
  width: 80%;
  max-width: 400px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 10px;
}

.max-input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.max-button {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  background: linear-gradient(to bottom right, #ff7e5f, #feb47b);
  border: none;
  color: white;
  border-radius: 25px;
  cursor: pointer;
}

.max-button:hover {
  background: linear-gradient(to bottom right, #feb47b, #ff7e5f);
}

.forgot-password {
  margin-top: 10px;
  color: grey;
  cursor: pointer;
}

.forgot-password:hover {
  color: black;
}

.dashboard {
  background-color: #fff;
}

.header {
  background-color: #ff7b00;
  color: white;
  padding: 10px;
  border-radius: 5px 5px 0 0;
  text-align: center;
  font-size: 24px;
}

.balance-card {
  background-color: #ff7b00;
  color: white;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.balance-content {
  text-align: left;
}

.balance-units,
.super-admin span,
.mobile span {
  display: block;
}

.balance-value {
  font-size: 40px;
  line-height: 1;
}
.balance-units {
  font-size: 23px;
  color: gray;
}
.summary-cards,
.menu-cards {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.summary-card,
.menu-card {
  background-color: #ff7b00;
  color: white;
  padding: 20px;
  border-radius: 5px;
  flex: 1;
  text-align: center;
  margin: 0 10px;
}

.summary-card .summary-title,
.menu-card {
  font-size: 20px;
}

.summary-card .summary-subtitle {
  font-size: 14px;
}

.menu-card {
  font-size: 18px;
  padding: 20px 0;
}


.box-shadow {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.border-box {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.form-outer {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px 6px;
  border-radius: 12px
}

.form-label {
  font-weight: bold;
  font-size: 13px;
  color: #4b4b4b;
}

.login-img {
  width: 80%;
  margin: auto;
}
.icon-card{
  font-size: 3rem;
  line-height: 0;
}
.card-a {
  background-image: linear-gradient(to right top, #051937, #004d7a, #008793, #00bf72, #a8eb12);
  color: white;
  padding: 17px;
  border-radius: 6px;
  font-size: 24px;
  font-size: 19px;
}

.card-b {
  background-image: linear-gradient(to right top, #964b08, #bd442c, #e03a56, #ef6875, #a14ca2);
  color: white;
  padding: 17px;
  border-radius: 6px;
  font-size: 24px;
  font-size: 19px;
}

.card-c {
  background: #9D44A6;
  background: linear-gradient(135deg, #9D44A6, #EAD25C);
  color: white;
  padding: 17px;
  border-radius: 6px;
  font-size: 24px;
  font-size: 19px;
}
.balance-title {
  margin-bottom: 10px;
  background-image: linear-gradient(45deg, #673AB7, #2196F3);
  color: white;
  border-radius: 7px;
  padding: 7px 15px;
  display: inline-block;
}
.recharge-dashboard {
  border: 1px solid #fef3f0;
  padding: 20px;
  border-radius: 5px;
  margin: 8px 0;
  background-image: linear-gradient(360deg, #ffdbd2, transparent);
}
.recharge-tile {
  background-image: linear-gradient(314deg, #ff7e5f, #bf654f);
  color: white;
  border-radius: 3px;
  padding: 6px 11px;
  position: relative;
}
.summary-title {
  display: block;
  font-weight: bold;
  font-size: 2rem;
}
.icon-recharge {
  position: absolute;
  top: 14px;
  right: 14px;
  font-size: 31px;
  opacity: .6;
}
.bb-m{
  border-bottom: 1px dashed lightgray;
  margin-bottom: 15px;
}

footer {
  margin-top: 20px;
}